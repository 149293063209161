import React, { createContext } from 'react'

import BlogEntry from 'components/BlogEntry'
import MainImage from 'components/MainImage'
import Page from 'components/Page'
import Section from 'components/Section'
import { MetaData } from 'components/Seo'
import { graphql, PageProps } from 'gatsby'
import { Locale, MetaType } from 'utils/enums'
import { isRunningInBrowser } from 'utils/tuixLocalStorage'
import { File, NodeQuery, Raw } from 'utils/types/common.types'

import CardSlider from 'components/CardSlider'
import SummaryCard from 'components/SummaryCard'
import { MainVariant } from 'tuix-webcomponents'
import { completeUrlPrefix, Node } from '../../utils/dataHelpers'

interface BlogEntryModel {
    createdBy: string
    createdAt: string
    slug: string
    node_locale: Locale
    title: string
    articleText: Raw
    miniatureImage: File
    coverImage: File
    description: string
    keywords: string[]
}

interface OppositeBlogSlugModel {
    slug: string
}

interface TitleModel {
    title: string
}

interface BlogEntryDataQueries {
    contentBlog: NodeQuery<BlogEntryModel>
    contentMetaData: NodeQuery<MetaData>
    oppositeBlogSlug: { nodes: Node<OppositeBlogSlugModel>[] }
    otherEntries: NodeQuery<BlogEntryModel>
    otherBlogsSection: NodeQuery<TitleModel>
    readBlogButtonText: NodeQuery<{ text: string }>
}

interface Props extends PageProps {
    pageContext: {
        slug: string
        locale: Locale
        id: string
    }
    data: BlogEntryDataQueries
}

export const slugContext = createContext()

export default ({ pageContext: { locale }, data }: Props): JSX.Element => {
    const {
        contentBlog: { nodes: contentBlog },
        oppositeBlogSlug: { nodes: oppositeBlogSlug },
        otherEntries: { nodes: otherBlogs },
        otherBlogsSection: { nodes: otherBlogsTitle },
        readBlogButtonText: { nodes: readBlogButtonText },
    } = data

    const {
        title,
        createdAt,
        articleText: { raw: richText, references },
        coverImage,
        description,
        keywords,
    } = contentBlog[0]
    const { slug: oppositeSlug } = oppositeBlogSlug[0]

    const metaData: MetaData = {
        title,
        url: '',
        description: { description },
        keywords,
        image: { file: { url: completeUrlPrefix(coverImage.file.url) } },
        type: MetaType.ARTICLE,
    }

    if (isRunningInBrowser) {
        metaData.url = window.location.toString()
    }

    return (
        <slugContext.Provider value={{ slug: oppositeSlug }}>
            <Page metaData={metaData} locale={locale}>
                <Section fadein={false}>
                    <MainImage imageURL={coverImage.file.url} imageURLDark={coverImage.file.url} />
                </Section>
                <Section fadein={false}>
                    <BlogEntry
                        title={title}
                        createdAt={new Date(createdAt)}
                        richText={richText}
                        blogEntryReferences={references}
                    />
                </Section>
                <Section fadein={false} backgroundColor={MainVariant.SECONDARY}>
                    <CardSlider title={otherBlogsTitle[0].title}>
                        {otherBlogs.map((blog, index) => (
                            <SummaryCard
                                key={index}
                                entry={{
                                    title: blog.title,
                                    image: blog.miniatureImage,
                                    content: blog.articleText,
                                    path: `blog/entry/${blog.slug}`,
                                }}
                                readMoreButtonText={readBlogButtonText[0].text}
                                locale={blog.node_locale}
                                className="embla__slide"
                            ></SummaryCard>
                        ))}
                    </CardSlider>
                </Section>
            </Page>
        </slugContext.Provider>
    )
}

export const query = graphql`
    query QueryBlogPost($slug: String!, $locale: String!, $id: String!) {
        contentBlog: allContentfulBlogPost(
            filter: { slug: { eq: $slug }, node_locale: { eq: $locale } }
        ) {
            nodes {
                createdBy
                createdAt
                slug
                node_locale
                title
                articleText {
                    raw
                    references {
                        title
                        contentful_id
                        description
                        file {
                            url
                            details {
                                image {
                                    height
                                    width
                                }
                            }
                        }
                    }
                }
                miniatureImage {
                    file {
                        url
                    }
                }
                coverImage {
                    file {
                        url
                    }
                }
                description
                keywords
            }
        }

        oppositeBlogSlug: allContentfulBlogPost(
            filter: { contentful_id: { eq: $id }, node_locale: { ne: $locale } }
        ) {
            nodes {
                slug
            }
        }

        contentMetaData: allContentfulSeo(
            filter: { contentful_id: { eq: "2MOy3DMDolAMyiay8EvErc" } }
        ) {
            nodes {
                title
                url
                description {
                    description
                }
                image {
                    file {
                        url
                    }
                }
                node_locale
            }
        }

        otherEntries: allContentfulBlogPost(
            sort: { order: DESC, fields: createdAt }
            filter: { slug: { ne: $slug }, node_locale: { eq: $locale } }
        ) {
            nodes {
                slug
                node_locale
                title
                articleText {
                    raw
                }
                miniatureImage {
                    file {
                        url
                    }
                }
                topic
            }
        }

        otherBlogsSection: allContentfulSection(
            filter: {
                contentful_id: { eq: "464Ywto0Oti4wQNBs2MpXG" }
                node_locale: { eq: $locale }
            }
        ) {
            nodes {
                node_locale
                contentful_id
                title
            }
        }

        readBlogButtonText: allContentfulTextButton(
            filter: {
                contentful_id: { eq: "6pZrAVz4lDkNreEsi8I3J6" }
                node_locale: { eq: $locale }
            }
        ) {
            nodes {
                text
            }
        }
    }
`
