import clsx from 'clsx'
import { TuixIconComponent } from 'components/TuixWebcomponents'
import { EmblaCarouselType } from 'embla-carousel'
import React, { useCallback, useEffect, useState } from 'react'
import { IconName, IconSizeVariant, TextVariant } from 'tuix-webcomponents'

type UsePrevNextButtonsType = {
    prevBtnDisabled: boolean
    nextBtnDisabled: boolean
    onPrevButtonClick: () => void
    onNextButtonClick: () => void
}

export enum SliderButtonSide {
    PREV = 'prev',
    NEXT = 'next',
}

interface SliderButtonProps {
    onClick: () => void
    disabled: boolean
    dark?: boolean
    side?: SliderButtonSide
}

export const usePrevNextButtons = (
    emblaApi: EmblaCarouselType | undefined,
): UsePrevNextButtonsType => {
    const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

    const onPrevButtonClick = useCallback(() => {
        if (!emblaApi) return
        emblaApi.scrollPrev()
    }, [emblaApi])

    const onNextButtonClick = useCallback(() => {
        if (!emblaApi) return
        emblaApi.scrollNext()
    }, [emblaApi])

    const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
        setPrevBtnDisabled(!emblaApi.canScrollPrev())
        setNextBtnDisabled(!emblaApi.canScrollNext())
    }, [])

    useEffect(() => {
        if (!emblaApi) {
            return
        }
        onSelect(emblaApi)
        emblaApi.on('reInit', onSelect).on('select', onSelect)
    }, [emblaApi, onSelect])

    return {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick,
    }
}

export const CardSliderButton = ({
    disabled,
    onClick,
    dark,
    side = SliderButtonSide.NEXT,
}: SliderButtonProps): JSX.Element => {
    return (
        <button
            aria-label={side === SliderButtonSide.NEXT ? 'Next' : 'Previous'}
            onClick={onClick}
            disabled={disabled}
            className={clsx('card-slider-button', dark && 'dark')}
        >
            <TuixIconComponent
                name={
                    side === SliderButtonSide.NEXT ? IconName.CHEVRON_RIGHT : IconName.CHEVRON_LEFT
                }
                size={IconSizeVariant.MEDIUM}
                dark={dark}
                color={disabled ? TextVariant.MAIN : TextVariant.PRIMARY}
            ></TuixIconComponent>
        </button>
    )
}
