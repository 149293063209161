import React, { createContext } from 'react'

import MainImageSlider from 'components/MainImageSlider'
import Page from 'components/Page'
import PortfolioEntryContent from 'components/PortfolioEntryContent'
import { ContactDataModel } from 'components/PortfolioEntryContent/PortfolioEntryContent'
import { PortfolioTextCardModel } from 'components/PortfolioTextCard/PortfolioTextCard'
import Section from 'components/Section'
import { MetaData } from 'components/Seo'
import { graphql } from 'gatsby'
import { completeUrlPrefix, getDataByLocale, mappingNode } from 'utils/dataHelpers'
import { Locale, MetaType } from 'utils/enums'
import { isRunningInBrowser } from 'utils/tuixLocalStorage'
import { NodeQuery } from 'utils/types/common.types'
import './index.scss'

interface OppositeSlug {
    slug: string
}

interface PortfolioEntryDataQueries {
    contentPortfolioEntry: NodeQuery<PortfolioTextCardModel>
    oppositeSlug: NodeQuery<OppositeSlug>
    contentMetaDataPortfolio: NodeQuery<MetaData>
    contactData: NodeQuery<ContactDataModel>
    contactUsText: NodeQuery<{ text: string }>
}

type Props = {
    data: PortfolioEntryDataQueries
    pageContext: {
        slug: string
        locale: Locale
    }
}

export const slugPortfolioContext = createContext()

export default ({ pageContext: { locale }, data }: Props): JSX.Element => {
    const {
        contentPortfolioEntry: {
            nodes: [portfolioEntry],
        },
        oppositeSlug: { nodes: oppositePortfolioSlug },
        contentMetaDataPortfolio: { nodes: nodesMetaData },
        contactData: { nodes: nodesContactData },
        contactUsText: { nodes: nodesContactUsText },
    } = data
    const metaData = getDataByLocale<MetaData>(nodesMetaData, locale)
    const contactData = mappingNode(nodesContactData, locale)
    const contactUsText = getDataByLocale(nodesContactUsText, locale)

    const {
        title,
        content: { raw: contentRichText, references: contentReferences },
        contentResume: { raw: contentResumeRichText },
        description,
        keywords,
        images,
    } = portfolioEntry

    const imagesUrls = images.map((image) => image.file.url)

    const oppositeSlug = oppositePortfolioSlug[0]

    metaData.title = title
    metaData.description = { description }
    metaData.keywords = keywords
    metaData.image = { file: { url: completeUrlPrefix(images[0].file.url) } }
    metaData.type = MetaType.ARTICLE

    if (isRunningInBrowser) {
        metaData.url = window.location.toString()
    }

    return (
        <slugPortfolioContext.Provider value={oppositeSlug}>
            <Page metaData={metaData} locale={locale}>
                <Section id="image-slider-portfolio-entry" fadein={false}>
                    <MainImageSlider imageUrls={imagesUrls} centerMode></MainImageSlider>
                </Section>
                <Section id="content-portfolio-entry" fadein={false}>
                    <PortfolioEntryContent
                        title={portfolioEntry.title}
                        contentRaw={contentRichText}
                        contentResumeRaw={contentResumeRichText || ''}
                        contactData={contactData}
                        contactUsText={contactUsText.text}
                        contentReferences={contentReferences}
                    ></PortfolioEntryContent>
                </Section>
            </Page>
        </slugPortfolioContext.Provider>
    )
}

export const query = graphql`
    query QueryPortfolioEntry($slug: String!, $locale: String!) {
        contentPortfolioEntry: allContentfulPortfolioEntry(
            filter: { slug: { eq: $slug }, node_locale: { eq: $locale } }
        ) {
            nodes {
                title
                slug
                images {
                    file {
                        url
                        fileName
                    }
                }
                content {
                    raw
                    references {
                        title
                        contentful_id
                        description
                        file {
                            url
                            details {
                                image {
                                    height
                                    width
                                }
                            }
                        }
                    }
                }
                contentResume {
                    raw
                }
                description
                keywords
                node_locale
            }
        }

        oppositeSlug: allContentfulPortfolioEntry(filter: { slug: { ne: $slug } }) {
            nodes {
                slug
            }
        }

        contentMetaDataPortfolio: allContentfulSeo(
            filter: { contentful_id: { eq: "1iN5e3PNF6AsLPaB7atkEC" } }
        ) {
            nodes {
                title
                url
                description {
                    description
                }
                image {
                    file {
                        url
                    }
                }
                node_locale
            }
        }

        contactData: allContentfulContactDataFields(sort: { fields: order, order: ASC }) {
            nodes {
                text
                order
                node_locale
                iconName
            }
        }

        contactUsText: allContentfulTextButton(
            filter: { contentful_id: { eq: "1bnuvPyb0a1DhPXThdGH7t" } }
        ) {
            nodes {
                node_locale
                text
            }
        }
    }
`
