import { useEffect, useState } from 'react'
import useWindowDimensions, { isClient } from 'utils/window'

const MOBILE_WIDTH = 640
const TABLET_WIDTH = 1280
const DESKTOP_WIDTH = 1920

const useDeviceWidth = () => {
    const { width } = useWindowDimensions()
    const [responsiveWidth, setResponsiveWidth] = useState(DESKTOP_WIDTH)
    const [isTablet, setIsTablet] = useState(() => {
        return isClient ? window.innerWidth <= TABLET_WIDTH : false
    })

    const [isMobile, setIsMobile] = useState(() => {
        return isClient ? window.innerWidth <= MOBILE_WIDTH : false
    })
    const [isDesktop, setIsDesktop] = useState(() => {
        return isClient ? window.innerWidth > TABLET_WIDTH : false
    })

    const checkResponsiveWidth = () => {
        if (isMobile) setResponsiveWidth(MOBILE_WIDTH)
        if (isTablet) setResponsiveWidth(TABLET_WIDTH)
        if (isDesktop) setResponsiveWidth(DESKTOP_WIDTH)
    }

    const checkDeviceWidth = () => {
        const width = window.innerWidth
        setIsTablet(width <= TABLET_WIDTH && width > MOBILE_WIDTH)
        setIsMobile(width <= MOBILE_WIDTH)
        setIsDesktop(width > TABLET_WIDTH)
    }

    useEffect(() => {
        checkDeviceWidth()
        checkResponsiveWidth()
    }, [width])

    return { isTablet, isMobile, isDesktop, responsiveWidth }
}

export default useDeviceWidth
