import * as React from 'react'

import type { EventName } from '@lit-labs/react'
import { createComponent } from '@lit-labs/react'
import { TuixInput } from 'tuix-webcomponents'

export const TuixInputComponent = createComponent({
    tagName: 'tuix-input',
    elementClass: TuixInput,
    react: React,
    events: {
        onChange: 'on-change' as EventName<CustomEvent>,
        onBlur: 'on-blur' as EventName<CustomEvent>,
    },
})
