import * as React from 'react'

import { createComponent } from '@lit-labs/react'
import { TuixIcon } from 'tuix-webcomponents'

export const TuixIconComponent = createComponent({
    tagName: 'tuix-icon',
    elementClass: TuixIcon,
    react: React,
})
