import * as React from 'react'

import { createComponent } from '@lit-labs/react'
import { TuixLink } from 'tuix-webcomponents'

export const TuixLinkComponent = createComponent({
    tagName: 'tuix-link',
    elementClass: TuixLink,
    react: React,
})
