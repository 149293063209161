import * as React from 'react'

import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import { TuixTextComponent } from 'components/TuixWebcomponents'
import { TextSize } from 'tuix-webcomponents'
import './RichTuixImage.scss'
import ImageView from 'components/ImageView/ImageView'
import { Image } from 'components/ImageView/types/imageTypes'

type Props = {
    image: Image
    title: string
    description: string
}

export default ({ image, title, description }: Props): JSX.Element => {
    const [isOpenImageView, setIsOpenImageView] = React.useState<boolean>(false)
    const { isDarkMode } = useDarkModeContext()
    const imageUrl = image.file.url

    const imageViewHandler = () => {
        setIsOpenImageView(!isOpenImageView)
    }

    return (
        <>
            <div className="blogEntry_imageContainer">
                <img src={imageUrl} alt={`${title} image`} onClick={imageViewHandler} />
                <TuixTextComponent size={TextSize.Body1} dark={isDarkMode}>
                    {description}
                </TuixTextComponent>
            </div>
            <ImageView
                imageUrl={imageUrl}
                alt={`${title} image view`}
                isOpen={isOpenImageView}
                onClose={imageViewHandler}
            />
        </>
    )
}
