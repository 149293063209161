export enum CodeBlockSupportedLanguages {
    BASH = 'bash',
    C = 'c',
    CLOJURE = 'clojure',
    CPP = 'cpp',
    CSHARP = 'csharp',
    DART = 'dart',
    ELIXIR = 'elixir',
    ELM = 'elm',
    ERLANG = 'erlang',
    FSHARP = 'fsharp',
    GRAPHQL = 'graphql',
    GO = 'go',
    GROOBY = 'groovy',
    HASKELL = 'haskell',
    HTML = 'html',
    JAVA = 'java',
    JAVASCRIPT = 'javascript',
    JSX = 'jsx',
    JULIA = 'julia',
    KOTLIN = 'kotlin',
    LISP = 'lisp',
    MAKEFILE = 'makefile',
    MATLAB = 'matlab',
    OBJECTIVEC = 'objectivec',
    OCAML = 'ocaml',
    PHP = 'php',
    PYTHON = 'python',
    R = 'r',
    RUBY = 'ruby',
    RUST = 'rust',
    SCALA = 'scala',
    SQL = 'sql',
    SWIFT = 'swift',
    TSX = 'tsx',
    TYPESCRIPT = 'typescript',
}

export const findNodes = (array, condition) => {
    return array.find((item) => condition(item))
}

export const getInternalContent = (input: string): { name: string; content: string } => {
    const pattern = /```(\w+)\s*([\s\S]+?)```/
    const match = pattern.exec(input)
    let name = ''
    let content = ''
    if (!match) return { name, content }
    name = match[1].trim() || ''
    content = match[2].trim() || ''
    return { name, content }
}

export const isSupportedLanguage = (language: string): boolean => {
    return Object.values(CodeBlockSupportedLanguages).includes(
        language as CodeBlockSupportedLanguages,
    )
}

export const mergeCodeBlocks = (richText: string) => {
    const data = JSON.parse(richText).content
    const mergedData = []
    const checkForType = (arrayToCheck) =>
        arrayToCheck?.content?.length === 1 &&
        arrayToCheck?.content[0]?.marks?.length &&
        arrayToCheck?.content[0]?.marks[0]?.type === 'code'
    for (let i = 0; i < data.length; i++) {
        const current = data[i]
        if (checkForType(current)) {
            const codeBlock = current
            let j = i
            while (data[j + 1] && checkForType(data[j + 1])) {
                codeBlock.content[0].value = `${codeBlock.content[0].value}\n\n${
                    data[j + 1].content[0].value
                }`
                j++
            }
            mergedData.push(codeBlock)
            i = j
        } else {
            mergedData.push(current)
        }
    }
    return mergedData
}
