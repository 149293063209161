import * as React from 'react'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import BlogTitle from 'components/BlogTitle'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import { TuixTextComponent, TuixIconComponent } from 'components/TuixWebcomponents'
import dayjs from 'dayjs'
import {
    LinkedinShareButton,
    TwitterShareButton,
    FacebookShareButton,
    EmailShareButton,
} from 'react-share'
import { IconName, IconSizeVariant, Palette, TextSize, TextVariant } from 'tuix-webcomponents'
import { mergeCodeBlocks } from 'utils/richTextHelpers'
import { isRunningInBrowser } from 'utils/tuixLocalStorage'
import './BlogEntry.scss'
import { assetReference } from 'utils/types/common.types'

import { TUIX_RICH_RENDER_OPTIONS_POST } from '../RichContenfulTuix/RichContentfulTuixEntry.consts'

export type BlogEntryProps = {
    richText: string
    title: string
    createdAt: Date
    blogEntryReferences: assetReference[]
}

export default ({
    createdAt,
    richText,
    title,
    blogEntryReferences,
}: BlogEntryProps): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const richObject = JSON.parse(richText)
    richObject.content = mergeCodeBlocks(richText)
    const content = documentToReactComponents(
        richObject,
        TUIX_RICH_RENDER_OPTIONS_POST(blogEntryReferences),
    )

    let url: string

    if (isRunningInBrowser) {
        url = window.location.toString()
    }

    return (
        <div className="blogEntry_container">
            <BlogTitle title={title} />
            <div className="blogEntry_subtitle">
                <TuixTextComponent dark={isDarkMode} size={TextSize.XS} color={TextVariant.GREY}>
                    {dayjs(createdAt).format('MMMM D, YYYY')}
                </TuixTextComponent>
            </div>
            <div className="blogEntry_mainContainer">
                <div className="share_container">
                    <LinkedinShareButton url={url} title={title}>
                        <TuixIconComponent
                            name={IconName.LINKEDIN}
                            size={IconSizeVariant.MEDIUM}
                            fill={isDarkMode ? TextVariant.MAIN : TextVariant.DARK_MAIN}
                            dark={isDarkMode}
                        />
                    </LinkedinShareButton>
                    <EmailShareButton url={url} title={title}>
                        <TuixIconComponent
                            name={IconName.MAIL}
                            size={IconSizeVariant.MEDIUM}
                            fill={isDarkMode ? TextVariant.MAIN : TextVariant.DARK_MAIN}
                            color={isDarkMode ? Palette.BLACK : Palette.WHITE}
                            dark={isDarkMode}
                        />
                    </EmailShareButton>
                    <TwitterShareButton url={url} title={title}>
                        <TuixIconComponent
                            name={IconName.TWITTER}
                            size={IconSizeVariant.MEDIUM}
                            dark={isDarkMode}
                            fill={isDarkMode ? TextVariant.MAIN : TextVariant.DARK_MAIN}
                        />
                    </TwitterShareButton>
                    <FacebookShareButton url={url} quote={title}>
                        <TuixIconComponent
                            name={IconName.FACEBOOK}
                            size={IconSizeVariant.MEDIUM}
                            dark={isDarkMode}
                            fill={isDarkMode ? TextVariant.MAIN : TextVariant.DARK_MAIN}
                        />
                    </FacebookShareButton>
                </div>
                <div className="blogEntry_textContainer">{content}</div>
            </div>
        </div>
    )
}
