const pathsToIgnore = process.env.GATSBY_PATHS_TO_IGNORE
    ? process.env.GATSBY_PATHS_TO_IGNORE.split(',')
    : []

const pathsToIgnoreExp = pathsToIgnore.length
    ? new RegExp(`^\/((en|de)\/)*(${pathsToIgnore.join('|')})($|\/.*)`) 
    : new RegExp(false)

module.exports = {
    pathsToIgnore,
    pathsToIgnoreExp,
}
