import * as React from 'react'

import type { EventName } from '@lit-labs/react'
import { createComponent } from '@lit-labs/react'
import { TuixIconButton } from 'tuix-webcomponents'

export const TuixIconButtonComponent = createComponent({
    tagName: 'tuix-icon-button',
    elementClass: TuixIconButton,
    react: React,
    events: {
        onClick: 'customClick' as EventName<CustomEvent>,
    },
})
