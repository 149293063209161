import * as React from 'react'

import type { EventName } from '@lit-labs/react'
import { createComponent } from '@lit-labs/react'
import { TuixDropdown } from 'tuix-webcomponents'

export const TuixDropdownComponent = createComponent({
    tagName: 'tuix-dropdown',
    elementClass: TuixDropdown,
    react: React,
    events: {
        onSelect: 'select' as EventName<CustomEvent>,
    },
})
