import * as React from 'react'

import { createComponent } from '@lit-labs/react'
import { TuixPaper } from 'tuix-webcomponents'

export const TuixPaperComponent = createComponent({
    tagName: 'tuix-paper',
    elementClass: TuixPaper,
    react: React,
})
