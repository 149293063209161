import { TuixTextComponent } from 'components/TuixWebcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import { EmblaOptionsType } from 'embla-carousel'
import ClassNames from 'embla-carousel-class-names'
import useEmblaCarousel from 'embla-carousel-react'
import React from 'react'
import { TextSize } from 'tuix-webcomponents'
import './CardSlider.scss'
import { CardSliderButton, SliderButtonSide, usePrevNextButtons } from './CardSliderArrowButtons'

interface Props {
    options?: EmblaOptionsType
    title?: string
    children?: React.ReactNode
}

export default ({ options, title, children }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const [emblaRef, emblaApi] = useEmblaCarousel(
        {
            align: 'start',
            containScroll: false,
            slidesToScroll: 'auto',
            ...options,
        },
        [ClassNames()],
    )

    const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } =
        usePrevNextButtons(emblaApi)

    return (
        <div className="card-carousel-container">
            <div className="embla__controls">
                <TuixTextComponent size={TextSize.M} dark={isDarkMode}>
                    {title}
                </TuixTextComponent>
                <div className="embla__buttons">
                    <CardSliderButton
                        onClick={onPrevButtonClick}
                        disabled={prevBtnDisabled}
                        dark={isDarkMode}
                        side={SliderButtonSide.PREV}
                    ></CardSliderButton>
                    <CardSliderButton
                        onClick={onNextButtonClick}
                        disabled={nextBtnDisabled}
                        dark={isDarkMode}
                        side={SliderButtonSide.NEXT}
                    ></CardSliderButton>
                </div>
            </div>
            <div className="embla__viewport" ref={emblaRef}>
                <div className="embla__container">{children}</div>
            </div>
        </div>
    )
}
