import LanguageSelector from 'components/LanguageSelector'
import ThemeToggle from 'components/ThemeToggle'
import {
    TuixButtonComponent,
    TuixIconComponent,
    TuixLinkComponent,
    TuixPaperComponent,
    TuixTextComponent,
} from 'components/TuixWebcomponents'
import useClickOutside from 'components/hooks/clickOutside/useClickOutside'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'
import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import {
    DarkPalette,
    IconName,
    IconSizeVariant,
    Palette,
    PaperElevation,
    TextSize,
    TextVariant,
} from 'tuix-webcomponents'
import { Locale } from 'utils/enums'
import { getLanguageByLocale } from '../../utils/language'
import { Asset } from '../../utils/types'
import './Navbar.scss'

interface Props {
    darkLogoUrl?: string
    logoUrl: string
    links: readonly NavbarLink[]
    contactUs: string
    locale: Locale
    languageLabel: string
    theme: string[]
}

interface NavbarLink {
    path: string
    text: string
}

export interface NavbarModel {
    logo: Asset
    darkLogo: Asset
    contactUs: string
    links: readonly NavbarLink[]
    languageLabel: string
    theme: string[]
}

export default ({
    logoUrl,
    links,
    contactUs,
    locale,
    darkLogoUrl,
    languageLabel,
    theme,
}: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const { isMobile, isTablet } = useDeviceWidth()

    const [hidden, setHidden] = useState(false)
    const [scrollY, setScrollY] = useState(0)
    const [timeoutId, setTimeoutId] = useState(null)

    const lang = `/${getLanguageByLocale(locale)}`

    const [showMenu, setShowMenu] = useState(false)
    const collapsibleMenuRef = useClickOutside(() => setShowMenu(false))
    const contactUsButtonLink: NavbarLink = {
        path: '/contact-us',
        text: contactUs,
    }

    const onHamburgerClick = () => setShowMenu(!showMenu)

    useEffect(() => {
        setShowMenu(false)
    }, [isDarkMode])

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY

            if (currentScrollY > scrollY && currentScrollY > 100) {
                setHidden(true)
                clearTimeout(timeoutId)
            } else {
                setHidden(false)
                setShowMenu(false)
                clearTimeout(timeoutId)
                const id = setTimeout(() => {
                    if (window.scrollY > 100) {
                        setHidden(true)
                    }
                }, 2000)
                setTimeoutId(id)
            }

            setScrollY(currentScrollY <= 0 ? 0 : currentScrollY)
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [scrollY, timeoutId])

    useEffect(() => {
        const handleTopScroll = () => {
            if (window.scrollY === 0) {
                setHidden(false)
            }
        }

        window.addEventListener('scroll', handleTopScroll)

        return () => {
            window.removeEventListener('scroll', handleTopScroll)
        }
    }, [])

    const hoverStyle = {
        '--variant-primary-hover': isDarkMode ? Palette.PRIMARY_950 : Palette.PRIMARY_50,
    } as React.CSSProperties

    const activeLinkStyle = {
        '--t-link-border-active-color': isDarkMode ? DarkPalette.MAIN : Palette.MAIN,
        '--t-link-text-color': isDarkMode ? DarkPalette.MAIN : Palette.MAIN,
    } as React.CSSProperties

    const navbarLinks = (linksToShow = [...links]) =>
        linksToShow.map((link) => (
            <Link
                key={link.path}
                to={`${lang}${link.path}`}
                className="navbar_link"
                style={hoverStyle}
                activeStyle={activeLinkStyle}
            >
                {!isTablet && (
                    <TuixLinkComponent dark={isDarkMode}>
                        <TuixTextComponent size={TextSize.Button}>{link.text}</TuixTextComponent>
                    </TuixLinkComponent>
                )}
                {isTablet && (
                    <TuixTextComponent
                        dark={isDarkMode}
                        size={TextSize.Field2}
                        color={TextVariant.PRIMARY}
                    >
                        {link.text}
                    </TuixTextComponent>
                )}
            </Link>
        ))

    return (
        <TuixPaperComponent
            elevation={isTablet ? PaperElevation.VARIANT_0 : PaperElevation.VARIANT_1}
            className="navbar_container"
        >
            <header
                style={{
                    backgroundColor: isDarkMode ? DarkPalette.SECTION_BG : Palette.SECTION_BG,
                }}
                className={`navbar_header ${hidden ? 'hidden' : ''}`}
            >
                <div className="navbar">
                    <div className="navbar_logo">
                        <Link to={lang}>
                            <figure>
                                <img src={isDarkMode ? darkLogoUrl : logoUrl} alt="Tuix logo" />
                            </figure>
                        </Link>
                    </div>
                    <div className="navbar_hamburger">
                        {!isMobile && (
                            <LanguageSelector lang={lang} languageLabel={languageLabel} />
                        )}
                        {!isMobile && <ThemeToggle />}
                        <div ref={collapsibleMenuRef}>
                            <TuixIconComponent
                                name={IconName.NAV_HAMBURGUER}
                                size={IconSizeVariant.MEDIUM}
                                color={isDarkMode ? Palette.WHITE : Palette.MAIN}
                                onClick={onHamburgerClick}
                            />
                            {showMenu && (
                                <TuixPaperComponent
                                    dark={isDarkMode}
                                    elevation={
                                        isMobile
                                            ? PaperElevation.VARIANT_0
                                            : PaperElevation.VARIANT_2
                                    }
                                    className="navbar_collapsible"
                                >
                                    <nav
                                        className="navbar_collapsible-links"
                                        style={{
                                            backgroundColor: isDarkMode
                                                ? DarkPalette.ELEVATION_1
                                                : Palette.WHITE,
                                        }}
                                    >
                                        {navbarLinks([...links])}
                                        {isMobile && (
                                            <LanguageSelector
                                                lang={lang}
                                                languageLabel={languageLabel}
                                            />
                                        )}
                                        {isMobile && <ThemeToggle theme={theme} />}
                                        {navbarLinks([contactUsButtonLink])}
                                    </nav>
                                </TuixPaperComponent>
                            )}
                        </div>
                    </div>
                    <nav className="navbar_links">
                        {navbarLinks()}
                        <LanguageSelector lang={lang} languageLabel={languageLabel} />
                        <ThemeToggle />
                        <Link to={`${lang}${contactUsButtonLink.path}`}>
                            <TuixButtonComponent>{contactUs}</TuixButtonComponent>
                        </Link>
                    </nav>
                </div>
            </header>
        </TuixPaperComponent>
    )
}
