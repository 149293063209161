import { Locale } from './enums'

export const getDataByLocale = <T>(nodeData: readonly Node<T>[], locale: Locale): Node<T> =>
    nodeData.find((node) => node.node_locale === locale) ?? nodeData[0]

export type Node<T> = T & {
    node_locale: Locale
}

export interface Edge<T> {
    node: Node<T>
}

export const mappingNode = <T>(nodes: readonly Node<T>[], locale: Locale): readonly Node<T>[] => {
    return nodes.filter((node) => node.node_locale === locale)
}

export const mappingEdge = <T>(edges: readonly Edge<T>[], locale: Locale): readonly Node<T>[] => {
    return edges.filter((edge) => edge.node.node_locale === locale).map((edge) => edge.node)
}

export const completeUrlPrefix = (url: string): string => {
    const httpRegex = /https*:/i
    if (httpRegex.test(url)) {
        return url
    }
    return `https:${url}`
}
