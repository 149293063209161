import * as React from 'react'

import clsx from 'clsx'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import './RichTuixTextList.scss'

type Props = {
    children: JSX.Element
}

export default ({ children }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    return <ul className={clsx('ul-rich-text', isDarkMode && 'dark')}>{children}</ul>
}
