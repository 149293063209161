export const isRunningInBrowser = typeof window !== 'undefined'

export default Object.freeze({
    setItem(key, value) {
        try {
            if (!isRunningInBrowser) return

            window.localStorage.setItem(key, JSON.stringify(value))
        } catch (e) {
            console.error('Error setting item in localStorage:', e)
        }
    },
    getItem<T>(key): T | null {
        try {
            if (!isRunningInBrowser) return null

            const item = window.localStorage.getItem(key)
            return item ? JSON.parse(item) : null
        } catch (error) {
            console.error('Error getting localStorage item:', error)
            return null
        }
    },
})
