import React from 'react'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import { TUIX_RICH_RENDER_OPTIONS } from 'components/RichContenfulTuix/RichContenfulTuix.consts'
import { TUIX_RICH_RENDER_OPTIONS_POST } from 'components/RichContenfulTuix/RichContentfulTuixEntry.consts'
import { TuixIconComponent, TuixTextComponent } from 'components/TuixWebcomponents'
import { IconName, IconSizeVariant, TextSize, TextVariant } from 'tuix-webcomponents'

import './PortfolioEntryContent.scss'
import { mergeCodeBlocks } from 'utils/richTextHelpers'
import { assetReference } from 'utils/types/common.types'

import ShareButton from './ShareButton'

export interface ContactDataModel {
    text: string
    order: number
    iconName: IconName
}

export interface Props {
    contentRaw: string
    contentResumeRaw: string
    contactData: readonly ContactDataModel[]
    contactUsText: string
    title: string
    contentReferences: assetReference[]
}

interface ContactDataProps {
    iconName: IconName
    text: string
}

const ContactDataField = ({ iconName, text }: ContactDataProps): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()

    return (
        <div className="contact-data-field">
            <TuixIconComponent
                name={iconName}
                color={TextVariant.MAIN}
                dark={isDarkMode}
                size={IconSizeVariant.MEDIUM}
            ></TuixIconComponent>
            <TuixTextComponent size={TextSize.Body1} color={TextVariant.PRIMARY} dark={isDarkMode}>
                {text}
            </TuixTextComponent>
        </div>
    )
}

export default ({
    contentRaw,
    contentResumeRaw,
    contactData,
    contactUsText,
    title,
    contentReferences,
}: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()

    const richObject = JSON.parse(contentRaw)
    richObject.content = mergeCodeBlocks(contentRaw)

    const content = documentToReactComponents(
        richObject,
        TUIX_RICH_RENDER_OPTIONS_POST(contentReferences),
    )

    const contentResume = documentToReactComponents(
        JSON.parse(contentResumeRaw),
        TUIX_RICH_RENDER_OPTIONS,
    )
    return (
        <div className="portfolio-content-container">
            <div className="project-title">
                <TuixTextComponent size={TextSize.M} color={TextVariant.PRIMARY} dark={isDarkMode}>
                    {title}
                </TuixTextComponent>
                <ShareButton pageTitle={title} />
            </div>
            <div className="portfolio-resume">{contentResume}</div>
            <div className="portfolio-text-container">
                <div className="portfolio-content">{content}</div>
            </div>
            <div className="contact-data-container">
                <TuixTextComponent size={TextSize.S} color={TextVariant.PRIMARY} dark={isDarkMode}>
                    {contactUsText}
                </TuixTextComponent>
                <div className="contact-data">
                    {contactData.map((field, index) => (
                        <ContactDataField
                            iconName={field.iconName}
                            key={index}
                            text={field.text}
                        ></ContactDataField>
                    ))}
                </div>
            </div>
        </div>
    )
}
