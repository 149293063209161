import * as React from 'react'

import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import { TuixTextComponent } from 'components/TuixWebcomponents'
import { TextSize } from 'tuix-webcomponents'
import './RichTuixText.scss'

type Props = {
    children: JSX.Element
    size: TextSize
}

const SIZE_MAP_WITH_TAG = {
    [TextSize.XL]: 'h1',
    [TextSize.L]: 'h2',
    [TextSize.M]: 'h3',
    [TextSize.S]: 'h4',
    [TextSize.XS]: 'h5',
    [TextSize.Body1]: 'p',
    [TextSize.Body2]: 'p',
}

export default ({ children, size }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()

    return (
        <TuixTextComponent size={size} dark={isDarkMode} className="rich-tuix-text">
            {React.createElement(SIZE_MAP_WITH_TAG[size], {}, children)}
        </TuixTextComponent>
    )
}
