import * as React from 'react'

import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'
import { TuixButtonComponent, TuixTextComponent } from 'components/TuixWebcomponents'
import { Link } from 'gatsby'
import { TextSize, TextVariant } from 'tuix-webcomponents'
import { Locale } from 'utils/enums'

import { MainImageTextPosition } from './MainImage.enums'
import './MainImage.scss'

type Props = {
    imageURL: string
    imageURLDark?: string
    title?: string
    subtitle?: string
    textPosition?: MainImageTextPosition
    buttonText?: string
    titleSize?: TextSize
    subTitleSize?: TextSize
    buttonURL?: string
    textVariantTitle?: TextVariant
    textVariantSubtitle?: TextVariant
    textVariantSubtitleDark?: TextVariant | null
    isStaticText?: boolean
}

interface File {
    url: string
}

interface BackgroundImage {
    title: string
    file: File
}

export interface MainImageModel {
    title: string
    titleSize: TextSize
    subtitle: string
    subTitleSize: TextSize
    node_locale: Locale
    textPosition: MainImageTextPosition
    buttonText: string
    backgroundImage: BackgroundImage
    backgroundImageDark?: BackgroundImage
    textVariantTitle: TextVariant
    textVariantSubtitle: TextVariant
    textVariantSubtitleDark?: TextVariant | null
    isStaticText: boolean
}

export default ({
    imageURL,
    imageURLDark,
    title,
    subtitle,
    textPosition,
    buttonText = '',
    titleSize = TextSize.XL,
    subTitleSize = TextSize.S,
    buttonURL,
    textVariantTitle = TextVariant.PRIMARY,
    textVariantSubtitle = TextVariant.PRIMARY,
    isStaticText = false,
    textVariantSubtitleDark,
}: Props): JSX.Element => {
    if (!textVariantSubtitleDark) {
        textVariantSubtitleDark = textVariantSubtitle
    }
    const { isDarkMode } = useDarkModeContext()
    const { isMobile, responsiveWidth } = useDeviceWidth()
    const computedImageUrl = isDarkMode ? imageURLDark : imageURL
    const computedSubtitleVariant = isDarkMode ? textVariantSubtitleDark : textVariantSubtitle
    let subtitleParts = subtitle?.split('|')
    if (!isMobile) {
        subtitleParts = [subtitleParts?.join(' ')]
    }

    return (
        <div
            className={`mainImage_container  mainImage_container${textPosition}`}
            style={{
                backgroundImage: `url("${computedImageUrl}?w=${responsiveWidth}&q=85&fm=webp")`,
            }}
        >
            <div
                className={`mainImage_contentContainer  mainImage_contentContainer${textPosition} ${
                    isStaticText ? 'mainImage_contentContainerStatic' : ''
                }`}
            >
                <div
                    className={`mainImage_textContainer mainImage_text${textPosition} ${
                        isStaticText ? 'mainImage_textContainerStatic' : ''
                    }`}
                >
                    <TuixTextComponent
                        size={titleSize}
                        color={textVariantTitle}
                        dark={isDarkMode}
                        className="mainImage_title"
                    >
                        <h1>{title}</h1>
                    </TuixTextComponent>
                    <TuixTextComponent
                        size={subTitleSize}
                        className="mainImage_subTitle"
                        color={computedSubtitleVariant}
                        dark={isDarkMode}
                    >
                        {subtitleParts?.map((part, index) => (
                            <div key={index}>
                                {part}
                                <br />
                            </div>
                        ))}
                    </TuixTextComponent>
                </div>
                {buttonText && (
                    <div className="mainImage_buttonContainer">
                        <Link to={buttonURL}>
                            <TuixButtonComponent>{buttonText}</TuixButtonComponent>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    )
}
