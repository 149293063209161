import { useState, useEffect } from 'react'

import { ScreenSizes } from 'tuix-webcomponents'

interface WindowDimensions {
    width: number
    height: number
}

const useWindowDimensions = (): WindowDimensions => {
    const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>({
        width: 0,
        height: 0,
    })

    useEffect(() => {
        const handleResize = () => {
            const { innerWidth: width, innerHeight: height } = window
            setWindowDimensions({
                width,
                height,
            })
        }
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return windowDimensions
}

export const MAX_MOBILE_WIDTH = ScreenSizes.MOBILE
export const MAX_TABLET_WIDTH = ScreenSizes.TABLET
export const MAX_LAPTOP_WIDTH = 1920
export const isClient = typeof window !== 'undefined'
export default useWindowDimensions
