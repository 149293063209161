import React from 'react'

import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { Block } from '@contentful/rich-text-types'
import clsx from 'clsx'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import { TuixButtonComponent, TuixTextComponent } from 'components/TuixWebcomponents'
import { Link } from 'gatsby'
import { MainVariant, TextSize, TextVariant } from 'tuix-webcomponents'
import { Locale } from 'utils/enums'
import { getLanguageByLocale } from 'utils/language'
import { File, Raw } from 'utils/types/common.types'

import './SummaryCard.scss'

export interface SummaryCardModel {
    title: string
    image: File
    content: Raw
    path: string
}

export interface Props {
    entry: SummaryCardModel
    readMoreButtonText?: string
    locale: Locale
    className?: string
}

export default ({ entry, readMoreButtonText, locale, className }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const rawContent: Block = JSON.parse(entry.content.raw)
    const onlyParagraphs = rawContent.content.filter((content) => content.nodeType === 'paragraph')
    rawContent.content = onlyParagraphs
    const plainDescription = documentToPlainTextString(rawContent).slice(0, 250)

    return (
        <div className={clsx(className)}>
            <div className={clsx('paper-summary-card', isDarkMode && 'dark')}>
                <div className="summary-card-container">
                    <img
                        src={entry.image?.file.url}
                        alt="main project image"
                        className="card-image"
                    />
                    <div className="card-description">
                        <div className="card-text">
                            <div className="title">
                                <TuixTextComponent size={TextSize.M} dark={isDarkMode}>
                                    {entry.title}
                                </TuixTextComponent>
                            </div>
                            <div className="short-description">
                                <TuixTextComponent
                                    size={TextSize.Body1}
                                    dark={isDarkMode}
                                    color={TextVariant.GREY}
                                >
                                    {plainDescription}
                                </TuixTextComponent>
                            </div>
                        </div>
                        <div>
                            <Link
                                to={`/${getLanguageByLocale(locale)}/${entry.path}`}
                                aria-label="see our blog post entry"
                            >
                                <TuixButtonComponent
                                    variant={MainVariant.SECONDARY}
                                    dark={isDarkMode}
                                    aria-label="see our blog post entry"
                                >
                                    {readMoreButtonText}
                                </TuixButtonComponent>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
