import clsx from 'clsx'
import { TuixTextComponent } from 'components/TuixWebcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import { useIntersectionObserver } from 'components/hooks/intersectionOberver/useIntersectionObserverContext'
import React, { PropsWithChildren } from 'react'
import { DarkPalette, MainVariant, Palette, TextSize, TextVariant } from 'tuix-webcomponents'
import './Section.scss'

export enum TextAlign {
    CENTER = 'Center',
    LEFT = 'Left',
}

interface Props {
    title?: string
    maxWidth?: string
    textAlign?: string
    className?: string
    id?: string
    backgroundColor?: MainVariant
    fadein?: boolean
}

export interface SectionModel {
    title: string
}

export default ({
    title,
    children,
    maxWidth,
    textAlign,
    className,
    id,
    backgroundColor = MainVariant.PRIMARY,
    fadein = true,
}: PropsWithChildren<Props>): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const elementRef = React.useRef(null)
    const registerElement = useIntersectionObserver()

    React.useEffect(() => {
        if (fadein) {
            registerElement(elementRef.current)
        }
    }, [elementRef])

    const bgColor =
        backgroundColor === MainVariant.PRIMARY
            ? isDarkMode
                ? DarkPalette.BLACK
                : Palette.WHITE
            : isDarkMode
            ? DarkPalette.SECTION_BG
            : Palette.SECTION_BG

    return (
        <section style={{ backgroundColor: bgColor }}>
            <div
                id={id}
                className={`section__container ${className ?? ''}`}
                style={{ maxWidth }}
                ref={elementRef}
            >
                {title && (
                    <div className={clsx('section__titleContainer', `section__title${textAlign}`)}>
                        <TuixTextComponent
                            size={TextSize.S}
                            color={TextVariant.DARK_MAIN}
                            dark={isDarkMode}
                        >
                            <h2>{title}</h2>
                        </TuixTextComponent>
                    </div>
                )}
                {children}
            </div>
        </section>
    )
}
