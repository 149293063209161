import React, { useContext, useState } from 'react'

import './LanguageSelector.scss'
import { useLocation } from '@reach/router'
import useClickOutside from 'components/hooks/clickOutside/useClickOutside'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'
import {
    TuixIconComponent,
    TuixPaperComponent,
    TuixTextComponent,
} from 'components/TuixWebcomponents'
import { navigate } from 'gatsby'
import { slugContext } from 'templates/entry'
import { slugPortfolioContext } from 'templates/portfolio/entry'
import {
    DarkPalette,
    IconName,
    IconSizeVariant,
    Palette,
    PaperElevation,
    TextSize,
    TextVariant,
} from 'tuix-webcomponents'

export interface Option {
    label: string
    value: string
    icon: JSX.Element
}

interface Props {
    lang: string
    languageLabel: string
}
interface SlugContext {
    slug?: string
}

const options: Option[] = [
    {
        label: 'Deutsch',
        icon: <span className="lang-selector_flag">🇩🇪</span>,
        value: 'de',
    },
    {
        label: 'English',
        icon: <span className="lang-selector_flag">🇺🇸</span>,
        value: 'en',
    },
]

export default ({ lang, languageLabel }: Props): JSX.Element => {
    const initVal = lang === '/de' ? options[0] : options[1]
    const [open, setOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState<Option>(initVal)
    const { isDarkMode } = useDarkModeContext()
    const location = useLocation()
    const { isMobile } = useDeviceWidth()
    const slug: SlugContext = useContext(slugContext)
    const slugPortfolio: SlugContext = useContext(slugPortfolioContext)
    let langSelectorRef = useClickOutside(() => setOpen(false))
    if (isMobile) langSelectorRef = null

    const toggleOptions = () => {
        setOpen(!open)
    }

    const setLanguageInURL = (val) => {
        const path = location.pathname.split('/')
        let begin = ''
        if (lang === '/en' || lang === '/de') {
            path[1] = val
        } else {
            path[0] = val
            begin = '/'
        }
        if (slug?.slug && initVal.value !== val) {
            path[4] = slug?.slug
        }
        if (slugPortfolio?.slug && initVal.value !== val) {
            path[4] = slugPortfolio?.slug
        }
        navigate(`${begin}${path.join('/')}`)
    }

    const onOptionClick = (value: Option) => () => {
        setOpen(false)
        setSelectedOption(value)
        setLanguageInURL(value.value)
    }

    const getTextVariant = (optionLabel) => {
        return selectedOption?.label === optionLabel ? TextVariant.MAIN : TextVariant.PRIMARY
    }

    const hoverStyle = {
        '--variant-primary-hover': isDarkMode ? Palette.PRIMARY_950 : Palette.PRIMARY_50,
    } as React.CSSProperties

    return (
        <div className="lang-selector_container" ref={langSelectorRef}>
            <div
                className="lang-selector_selected-option"
                onClick={toggleOptions}
                style={isMobile ? hoverStyle : {}}
            >
                <TuixTextComponent
                    size={isMobile ? TextSize.Field2 : TextSize.Button}
                    color={isMobile ? TextVariant.PRIMARY : TextVariant.MAIN}
                    dark={isDarkMode}
                >
                    {isMobile ? languageLabel : selectedOption?.label?.slice(0, 2) ?? '-'}
                </TuixTextComponent>
                <TuixIconComponent
                    dark={isDarkMode}
                    name={IconName.CHEVRON_DOWN}
                    size={IconSizeVariant.MEDIUM}
                    color={isMobile ? TextVariant.PRIMARY : TextVariant.MAIN}
                />
            </div>
            <TuixPaperComponent
                dark={isDarkMode}
                elevation={isMobile ? PaperElevation.VARIANT_0 : PaperElevation.VARIANT_2}
                className="lang-selector_optionen"
                style={{ display: open ? 'block' : 'none' }}
            >
                <div
                    style={{
                        backgroundColor: isDarkMode
                            ? DarkPalette.ELEVATION_2
                            : isMobile
                            ? Palette.SECTION_BG
                            : Palette.WHITE,
                    }}
                >
                    {options.map((option) => (
                        <div
                            key={option.label}
                            className="lang-selector_option"
                            style={hoverStyle}
                            onClick={onOptionClick(option)}
                        >
                            <TuixTextComponent
                                dark={isDarkMode}
                                size={isMobile ? TextSize.Field2 : TextSize.Field1}
                                color={getTextVariant(option.label)}
                            >
                                {option.label}
                            </TuixTextComponent>
                            {!isMobile && option.icon}
                        </div>
                    ))}
                </div>
            </TuixPaperComponent>
        </div>
    )
}
