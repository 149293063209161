import * as React from 'react'

import { createComponent } from '@lit-labs/react'
import { TuixText } from 'tuix-webcomponents'

export const TuixTextComponent = createComponent({
    tagName: 'tuix-text',
    elementClass: TuixText,
    react: React,
})
