import React, { useCallback, useEffect, useState } from 'react'

import clsx from 'clsx'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'
import { TuixIconComponent, TuixPaperComponent } from 'components/TuixWebcomponents'
import ClassNames from 'embla-carousel-class-names'
import useEmblaCarousel from 'embla-carousel-react'
import { IconName, IconSizeVariant, Palette, PaperElevation } from 'tuix-webcomponents'

import './MainImageSlider.scss'

export interface Props {
    imageUrls: string[]
    centerMode?: boolean
}

export default ({ imageUrls, centerMode }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const { isTablet, isMobile } = useDeviceWidth()
    const isArrowVisible = (isTablet && !centerMode) || isMobile
    const [scrollSnaps, setScrollSnaps] = useState<number[]>([])
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [emblaRef, emblaApi] = useEmblaCarousel(
        {
            align: centerMode && !isMobile ? 'center' : 'start',
            loop: true,
        },
        [ClassNames()],
    )

    const scrollPrev = useCallback(() => {
        if (emblaApi) emblaApi.scrollPrev()
    }, [emblaApi])

    const scrollNext = useCallback(() => {
        if (emblaApi) emblaApi.scrollNext()
    }, [emblaApi])

    const onSelect = useCallback(() => {
        if (!emblaApi) return
        setSelectedIndex(emblaApi.selectedScrollSnap())
    }, [emblaApi, setSelectedIndex])

    const scrollTo = React.useCallback((index) => emblaApi && emblaApi.scrollTo(index), [emblaApi])

    useEffect(() => {
        if (!emblaApi) return
        onSelect()
        setScrollSnaps(emblaApi.scrollSnapList())
        emblaApi.on('select', onSelect)
    }, [emblaApi, setScrollSnaps, onSelect])

    return (
        <div className={clsx('main-image-carousel-container', isDarkMode && 'dark')}>
            <div className="embla__viewport" ref={emblaRef}>
                <div className={clsx('embla__container', centerMode && 'center-mode')}>
                    {imageUrls.map((imageUrl, index) => (
                        <TuixPaperComponent
                            elevation={PaperElevation.VARIANT_2}
                            className="embla__slide"
                            key={index}
                        >
                            <img
                                src={imageUrl}
                                alt="main project image"
                                className={clsx(
                                    'main-image-carousel-image',
                                    isDarkMode && 'dark',
                                    'embla__slide__img',
                                    index === selectedIndex && 'embla__main__img',
                                )}
                            />
                        </TuixPaperComponent>
                    ))}
                </div>
            </div>
            {isArrowVisible && (
                <>
                    <TuixIconComponent
                        name={IconName.CHEVRON_LEFT}
                        color={Palette.WHITE}
                        size={IconSizeVariant.MEDIUM}
                        className="arrow left"
                        onClick={scrollPrev}
                    ></TuixIconComponent>
                    <TuixIconComponent
                        name={IconName.CHEVRON_RIGHT}
                        color={Palette.WHITE}
                        size={IconSizeVariant.MEDIUM}
                        className="arrow right"
                        onClick={scrollNext}
                    ></TuixIconComponent>
                </>
            )}
            <div className={clsx('embla__navigator', centerMode && 'portfolio-entry-banner')}>
                {scrollSnaps.map((_, index) => (
                    <div
                        className={clsx('embla__dots', index === selectedIndex && 'is-selected')}
                        key={index}
                        onClick={() => scrollTo(index)}
                    />
                ))}
            </div>
        </div>
    )
}
