import { TuixIconButtonComponent } from 'components/TuixWebcomponents'
import React, { useState } from 'react'
import { IconName, MainVariant } from 'tuix-webcomponents'
import './ImageView.scss'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'

interface Props {
    imageUrl: string
    alt: string
    isOpen: boolean
    onClose: () => void
}

export default ({ imageUrl, alt, isOpen = false, onClose }: Props): JSX.Element => {
    const [zoom, setZoom] = useState<number>(1)
    const { isDarkMode } = useDarkModeContext()

    const zoomHandler = () => {
        if (zoom < 3) {
            setZoom(zoom + 1)
        } else {
            setZoom(1)
        }
    }

    return (
        <>
            {isOpen && (
                <div className="image-view-container">
                    <div
                        className={
                            isDarkMode ? 'image-view-background-dark' : 'image-view-background'
                        }
                        onClick={onClose}
                    ></div>
                    <TuixIconButtonComponent
                        className="close-icon"
                        variant={isDarkMode ? MainVariant.SECONDARY : MainVariant.PRIMARY}
                        icon={IconName.X}
                        onClick={onClose}
                        dark={isDarkMode}
                    />
                    <img
                        className={`image-${zoom}x`}
                        src={imageUrl}
                        alt={alt}
                        onClick={zoomHandler}
                    />
                </div>
            )}
        </>
    )
}
